body {
  margin: 0;
  padding: 0;
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

#root {
  width: 100%;
  height: 100%;
  background: linear-gradient(-225deg, #d8fefe 0%, #d2cffe 56%, #ffd7dc 100%);
  background-size: 200% 200%;
  animation: gradientAnimation 10s ease infinite;
}
@keyframes gradientAnimation {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

@keyframes animateBorder {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.glowing-button {
  padding: 15px 30px;
  font-size: 16px;
  color: white;
  background-color: #000;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(255, 0, 150, 0.6), 0 0 20px rgba(0, 150, 255, 0.6), 0 0 30px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease;
  animation: gradientAnimation 10s ease infinite;
}

/* .glowing-button:hover {
  box-shadow: 0 0 40px rgba(255, 0, 150, 1), 0 0 40px rgba(0, 150, 255, 1), 0 0 60px rgba(0, 0, 0, 1);
} */